import React from "react";
import { Link } from "react-router-dom";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import Footer from "../partials/Footer";
import { ReactComponent as Karma } from "../assets/karma.svg";

function TOS() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>
        <section>
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-left pb-12 md:pb-20 ">
              <div
                className={`text-justify bg-primary-6 rounded-lg text-lilac-500 font-serif`}
              >
                <div
                  className="text-center mb-6 font-black mt-28"
                  data-aos="fade-up"
                >
                  <p>Generos Terms of Service</p>
                  <p>Version 1.0</p>
                  <p>Last revised on: February 2, 2022</p>
                </div>

                <p className="mb-6">
                  These Terms of Service constitute a legally binding agreement
                  made between You, whether personally or on behalf of an entity
                  (<span className="font-black">“You,” “Your,” “Yourself”</span>
                  ) and Generos, Inc (the{" "}
                  <span className="font-black">“Company,” “We,” </span> or{" "}
                  <span className="font-black">“Us”</span>) concerning Your
                  access to and use of the https://generos.io website and the
                  Generos Application as well as any other media form, mobile
                  website or mobile application (including a progressive web
                  application) related, linked, or otherwise connected thereto
                  (collectively, the <span className="font-black">“Site"</span>{" "}
                  and the <span className="font-black">“Application"</span>).
                  Certain features of the Application may be subject to
                  additional guidelines, terms, or rules, which will be posted
                  on the Application in connection with such features. All such
                  additional terms, guidelines, and rules are incorporated by
                  reference into these Terms.
                </p>

                <p className="mb-6">
                  THESE TERMS OF USE (THE{" "}
                  <span className="font-black">“TERMS”</span>) SET FORTH THE
                  LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF
                  THE APPLICATION. BY ACCESSING OR USING THE APPLICATION, YOU
                  ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY
                  THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU
                  HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE
                  TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU
                  REPRESENT). YOU MAY NOT ACCESS OR USE THE SITE OR ACCEPT THE
                  TERMS IF YOU ARE AT LEAST 13 YEARS OLD. IF YOU DO NOT AGREE
                  WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS
                  AND/OR USE THE APPLICATION.
                </p>

                <p className="mb-6">
                  THESE TERMS REQUIRE THE USE OF ARBITRATION (SECTION 9.2) ON AN
                  INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS
                  OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU
                  IN THE EVENT OF A DISPUTE.
                </p>

                <div className="mb-6 font-black text-xl">1. Accounts</div>

                <p className="mb-6">
                  <span className="font-black">1.1 Account Creation</span>. In
                  order to use certain features of the Application You must
                  register for an account (
                  <span className="font-black">“Account”</span>) and provide
                  certain information about Yourself as prompted by the account
                  registration form. You represent and warrant that: (a) You are
                  located within the United States; (b) all required
                  registration information You submit is truthful and accurate;
                  (c) You will maintain the accuracy of such information and
                  update; accordingly, and (d) You have the legal capacity to
                  create an Account and agree to abide by these Terms. You may
                  delete Your Account at any time, for any reason, by following
                  the instructions on the Site and/or the Application. In the
                  event You provide information that is untrue, inaccurate, not
                  current, or incomplete, We have the right to suspend or
                  terminate Your Account and refuse any and all current or
                  future use of the Application in accordance with section 8.
                </p>

                <p className="mb-6">
                  <span className="font-black">
                    1.2 Account Responsibilities
                  </span>
                  . You are responsible for maintaining the confidentiality of
                  Your Account login information and are fully responsible for
                  all activities that occur under Your Account. You agree to
                  immediately notify Company of any unauthorized use or
                  suspected unauthorized use of Your Account or any other breach
                  of security. Company cannot and will not be liable for any
                  loss or damage arising from Your failure to comply with the
                  above requirements.
                </p>

                <div className="mb-6 font-black text-xl">
                  2. Access to the Application{" "}
                </div>

                <p className="mb-6">
                  <span className="font-black">2.1 License</span>. Subject to
                  these Terms, Company grants You a non-transferable,
                  non-exclusive, revocable, limited license to use and access
                  the Site solely for Your own personal, noncommercial use.
                </p>

                <p className="mb-6">
                  <span className="font-black">2.2 Certain Restrictions</span>.
                  The rights granted to You in these Terms are subject to the
                  following restrictions: (a) You shall not license, sell, rent,
                  lease, transfer, assign, distribute, host, or otherwise
                  commercially exploit the Application, whether in whole or in
                  part, or any content displayed on the Application; (b) You
                  shall not modify, make derivative works of, decipher,
                  disassemble, reverse compile or reverse engineer any part of
                  the Application; (c) You shall not access the Application in
                  order to build a similar or competitive website, product, or
                  service; (d) except as expressly stated herein, no part of the
                  Application may be copied, reproduced, distributed,
                  republished, downloaded, displayed, posted or transmitted in
                  any form or by any means; (e) You shall not access the
                  Application through automated and non-human means, whether
                  through a bot, scripts, or otherwise; (f) You shall not use
                  the Application for any illegal and unauthorized purpose; (g)
                  You shall not use the Application to violate any law or
                  regulation; (h) You (a human being) shall not use more than
                  one Account to earn gencoin in any twenty four (24) hour
                  period; (i) You shall not make any unauthorized use of the
                  Application including collecting usernames and/or email
                  addresses of users by electronic or other means for the
                  purpose of sending unsolicited emails or creating user
                  accounts by automated means or under false pretenses; (j) You
                  shall not circumvent, disable, or otherwise interfere with
                  security-related features of the Application, including
                  features that prevent or restrict the use or copying of any
                  content or enforce limitations on the use of the Application
                  and/or the content contained therein; (k) You shall not engage
                  in unauthorized framing of or linking to the Application; (l)
                  You shall not trick, defraud, or mislead Us and other users,
                  especially in any attempt to learn sensitive account
                  information such as user passwords; (m) You shall not make
                  improper use of our support services or submit false reports
                  of abuse or misconduct; (n) You shall not attempt to
                  impersonate another user or person or use the username of
                  another user; (o) You shall not sell or otherwise transfer
                  Your profile; (p) You shall not use any information obtained
                  from the Application in order to harass, abuse, or harm
                  another person; (q) You shall not upload or transmit (or
                  attempt to upload or to transmit) viruses, Trojan horses, or
                  other material that interferes with any party’s uninterrupted
                  use and enjoyment of the Application or modifies, impairs,
                  disrupts, alters, or interferes with the use, features,
                  functions, operation, or maintenance of the Application; and
                  (r) You shall not be located outside of the United States
                  territory. Unless otherwise indicated, any future release,
                  update, or other addition to functionality of the Application
                  shall be subject to these Terms.
                </p>

                <p className="mb-6">
                  <span className="font-black">2.3 Modification</span>. Company
                  reserves the right, at any time, to modify or suspend the
                  Application (in whole or in part) with or without notice to
                  You. In the event the Application is discontinued, the Company
                  agrees to notify You of such discontinuation with thirty (30)
                  days’ notice, at which point You are responsible for taking
                  appropriate means to close Your account. You agree that
                  Company will not be liable to You or to any third party for
                  any modification, suspension, or discontinuation of the
                  Application or any part thereof.
                </p>

                <p className="mb-6">
                  <span className="font-black">2.4 Intellectual Property</span>.
                  You acknowledge that all the intellectual property rights,
                  including copyrights, patents, trademarks, and trade secrets,
                  in the Site and Application and its content are owned by
                  Company or Company’s suppliers. Neither these Terms (nor Your
                  access to the Application) transfers to You or any third party
                  any rights, title or interest in or to such intellectual
                  property rights, except for the limited access rights
                  expressly set forth in section 2.1. Company and its suppliers
                  reserve all rights not granted in these Terms. There are no
                  implied licenses granted under these Terms. Unless otherwise
                  indicated, the Site and the Application are Our proprietary
                  property and all source code, database, functionality,
                  software, website design, audio, video, text, photographs, and
                  graphics on the Site and the Application and trademarks,
                  service marks and logos contained therein (the “Marks") are
                  owned, controlled by Us or licensed to Us, and are protected
                  by copyright and trademark laws and various other intellectual
                  property rights. Except as expressly provided in these Terms
                  of Use, no part of the Site, the Application as well as the
                  content or Marks may be copied, reproduced, aggregated,
                  republished, uploaded, posted, publicly displayed, encoded,
                  translated, transmitted, distributed, sold, licensed, or
                  otherwise exploited for any commercial purpose whatsoever,
                  without our express prior written permission.
                </p>

                <p className="mb-6">
                  Provided that You are eligible to use the Application, You are
                  granted a limited license to access and use the Application or
                  to download or print a copy of any portion of the content to
                  which You have properly gained access solely for Your
                  personal, non-commercial use. We reserve all rights not
                  expressly granted to You in and to the Site, Application, any
                  content of the Site, and the Marks.
                </p>

                <div className="mb-6 font-black text-xl">
                  3. Payments; Taxes; Refund
                </div>

                <p className="mb-6">
                  <span className="font-black">3.1 Payments</span>. Payment
                  processing services for You on the Application are provided by
                  Stripe and are subject to the
                  <Link
                    className="text-primary-1 font-black"
                    to={{
                      pathname: "https://stripe.com/connect-account/legal/full",
                    }}
                    target="_blank"
                  >
                    {" Stripe Connected Account Agreement"}
                  </Link>
                  , which includes the
                  <Link
                    className="text-primary-1 font-black"
                    to={{ pathname: "https://stripe.com/legal" }}
                    target="_blank"
                  >
                    {" Stripe Terms of Service "}
                  </Link>
                  (collectively, the “Stripe Services Agreement”). By agreeing
                  to these Terms or continuing to operate an Account on the
                  Application, You agree to be bound by the Stripe Services
                  Agreement, as the same may be modified by Stripe from time to
                  time. As a condition of the Application enabling
                  payment-processing services through Stripe, You agree to
                  provide the Application accurate and complete information
                  about You and/or Your business, and You authorize the
                  Application to share it and transaction information related to
                  Your use of the payment processing services provided by
                  Stripe.
                </p>

                <p className="mb-6">
                  <span className="font-black">3.2 Taxes</span>. All fees are
                  exclusive of taxes. You understand and acknowledge that You
                  are solely responsible for determining what, if any, taxes
                  apply to Your transactions on the Application. You are
                  responsible for paying all taxes, assessments, charges, or
                  levies imposed by any taxing authority relating to the
                  transactions on the Application. Applicable taxes may include
                  but are not limited to sales, goods and services, use, value
                  added, and gross receipts taxes. The Company is not
                  responsible for determining the taxes or Your reporting
                  requirements applicable to Your transactions on the
                  Application. You agree to report all required taxes under Your
                  applicable jurisdiction.
                </p>

                <p className="mb-6">
                  <span className="font-black">3.3 Refunds</span>. All sales are
                  final, and the Company does not offer any money-back
                  guarantee. You acknowledge and agree that You shall not be
                  entitled to a refund for any virtual goods purchased in the
                  Application under any circumstances. You understand and
                  acknowledge that due to the consumptive nature of the virtual
                  goods in the Application’s algorithm no refunds will be
                  provided.
                </p>

                <div className="mb-6 font-black text-xl">
                  4. Indemnification
                </div>

                <p className="mb-6">
                  You agree to indemnify and hold Company (and its officers,
                  directors, representatives, employees, and agents) harmless,
                  including costs and attorneys’ fees, from any claim or demand
                  made by any third party due to or arising out of (a) Your use
                  of the Application, (b) Your violation of these Terms, or (c)
                  Your violation of applicable laws or regulations. Company
                  reserves the right, at Your expense, to assume the exclusive
                  defense and control of any matter for which You are required
                  to indemnify Us, and You agree to cooperate with Our defense
                  of these claims. You agree not to settle any matter without
                  the prior written consent of Company. Company will use
                  reasonable efforts to notify You of any such claim, action or
                  proceeding upon becoming aware of it.
                </p>

                <div className="mb-6 font-black text-xl">
                  5. Third-Party Links; Other Users
                </div>

                <p className="mb-6">
                  <span className="font-black">5.1 Third-Party Links</span>. The
                  Site and Application may contain links to third-party websites
                  and services (
                  <span className="font-black">“Third-Party Links”</span>). Such
                  Third-Party Links are not under the control of Company, and
                  Company is not responsible for any Third-Party Links. Company
                  provides access to these Third-Party Links only as a
                  convenience to You, and does not review, approve, monitor,
                  endorse, warrant, or make any representations with respect to
                  Third-Party Links. You use all Third-Party Links at Your own
                  risk and should apply a suitable level of caution and
                  discretion in doing so. When You click on any of the
                  Third-Party Links, the applicable third party’s terms and
                  policies apply, including the third party’s privacy and data
                  gathering practices. You should make whatever investigation
                  You feel necessary or appropriate before proceeding with any
                  transaction in connection with such Third-Party Links.
                </p>

                <p className="mb-6">
                  <span className="font-black">5.2 Other Users</span>. You
                  acknowledge and agree that We are not responsible for
                  communications between You and other users. Your interactions
                  with other Application users are solely between You and such
                  users. You agree that Company will not be responsible for any
                  loss or damage incurred as the result of any such
                  interactions. If there is a dispute between You and any
                  Application user, We are under no obligation to become
                  involved.
                </p>

                <p className="mb-6">
                  <span className="font-black">5.3 Release</span> You hereby
                  release and forever discharge the Company (and our officers,
                  employees, agents, successors, and assigns) from, and hereby
                  waive and relinquish, each and every past, present and future
                  dispute, claim, controversy, demand, right, obligation,
                  liability, action and cause of action of every kind and nature
                  (including personal injuries, death, and property damage),
                  that has arisen or arises directly or indirectly out of, or
                  that relates directly or indirectly to, the Application
                  (including any interactions with, or act or omission of, other
                  Application users or any Third-Party Links). IF YOU ARE A
                  CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE
                  SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES:
                  “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE
                  CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR
                  AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR
                  HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH
                  THE DEBTOR.”
                </p>

                <div className="mb-6 font-black text-xl">6. Disclaimers</div>

                <p className="mb-6">
                  THE APPLICATION IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE”
                  BASIS, AND COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY
                  AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER
                  EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR
                  CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                  PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR
                  NON-INFRINGEMENT. WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT
                  THE APPLICATION WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE
                  ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR
                  WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL
                  CODE, COMPLETE, LEGAL, OR SAFE. THE COMPANY MAKES NO
                  WARRANTIES REGARDING THE PERFORMANCE OR OPERATION OF THIS SITE
                  AND THE APPLICATION. THE COMPANY MAKES NO REPRESENTATIONS OR
                  WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO YOUR SUCCESS
                  IN THE USE OF THE APPLICATION. THE COMPANY MAKES NO GUARANTEE
                  THAT YOU WILL SUCCESFULLY USE THE APPLICATION OR GUARANTEE ANY
                  SPECIFIC OUTCOME AS A RESULT OF YOUR USE OF THE APPLICATION.
                </p>

                <p className="mb-6">
                  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
                  WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME
                  JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED
                  WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                </p>

                <div className="mb-6 font-black text-xl">
                  7. Limitation on Liability
                </div>

                <p className="mb-6">
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
                  COMPANY (OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY
                  FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF
                  SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL,
                  EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING
                  FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY
                  TO USE, THE APPLCIATION, EVEN IF COMPANY HAS BEEN ADVISED OF
                  THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE
                  SITE IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE
                  SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER
                  SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.
                </p>

                <p className="mb-6">
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING
                  ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO
                  YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT
                  (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE
                  ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY US
                  DOLLARS ($50). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT
                  ENLARGE THIS LIMIT. YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO
                  LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS
                  AGREEMENT.
                </p>

                <div className="mb-6 font-black text-xl">
                  8. Term and Termination
                </div>

                <p className="mb-6">
                  Subject to this Section, these Terms will remain in full force
                  and effect while You have a user Account on the Application.
                  We may suspend or terminate Your rights to use the Application
                  (including Your Account) at any time, at Our sole discretion,
                  for any use of the Application in violation of these Terms.
                  Upon termination of Your rights under these Terms, Your
                  Account and right to access and use the Application will
                  terminate immediately. Company will not have any liability
                  whatsoever to You for any termination of Your rights under
                  these Terms, including for termination of Your Account. Even
                  after Your rights under these Terms are terminated, the
                  following provisions of these Terms will remain in effect:
                  Sections 2.2 through 4, and Sections 3 through 9.
                </p>

                <div className="mb-6 font-black text-xl">9. General</div>

                <p className="mb-6">
                  <span className="font-black">9.1 Changes</span>. These Terms
                  are subject to occasional revision, and if We make any
                  substantial changes, We may notify You by sending You an
                  e-mail to the last e-mail address You provided to Us (if any),
                  and/or by prominently posting notice of the changes on our
                  Application. You are responsible for providing Us with Your
                  most current e-mail address. In the event that the last e-mail
                  address that You have provided Us is not valid, or for any
                  reason is not capable of delivering to You the notice
                  described above, Our dispatch of the e-mail containing such
                  notice will nonetheless constitute effective notice of the
                  changes described in the notice. These changes will be
                  effective immediately for new users of our Application.
                  Continued use of our Application following notice of such
                  changes shall indicate Your acknowledgement of such changes
                  and agreement to be bound by the terms and conditions of such
                  changes.
                </p>

                <p className="mb-6 font-black">
                  9.2 Dispute Resolution.
                  <span className="italic">
                    If You have any concern or dispute about the Service, You
                    agree to first try to resolve the dispute informally by
                    contacting the Company. Please read this Arbitration
                    Agreement carefully. It is part of Your contract with
                    Company and affects Your rights. It contains procedures for
                    MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.
                  </span>
                </p>

                <p className="mb-6">
                  (a){" "}
                  <span className="italic">
                    Applicability of Arbitration Agreement
                  </span>
                  . All claims and disputes (excluding claims for injunctive or
                  other equitable relief as set forth below) in connection with
                  the Terms or the use of any product or service provided by the
                  Company that cannot be resolved informally or in small claims
                  court shall be resolved by binding arbitration on an
                  individual basis under the terms of this Arbitration
                  Agreement. Unless otherwise agreed to, all arbitration
                  proceedings shall be held in English. This Arbitration
                  Agreement applies to You and the Company, and to any
                  subsidiaries, affiliates, agents, employees, predecessors in
                  interest, successors, and assigns, as well as all authorized
                  or unauthorized users or beneficiaries of services or goods
                  provided under the Terms.
                </p>

                <p className="mb-6">
                  (b){" "}
                  <span className="italic">
                    Notice Requirement and Informal Dispute Resolution
                  </span>
                  . Before either party may seek arbitration, the party must
                  first send to the other party a written Notice of Dispute
                  (“Notice”) describing the nature and basis of the claim or
                  dispute, and the requested relief. A Notice to the Company
                  should be sent to: 246 NW 41 avenue, Miami, FL 33126. After
                  the Notice is received, You and the Company may attempt to
                  resolve the claim or dispute informally. If You and the
                  Company do not resolve the claim or dispute within thirty (30)
                  days after the Notice is received, either party may begin an
                  arbitration proceeding. The amount of any settlement offer
                  made by any party may not be disclosed to the arbitrator until
                  after the arbitrator has determined the amount of the award,
                  if any, to which either party is entitled.
                </p>

                <p className="mb-6">
                  (c) <span className="italic">Arbitration Rules</span>.
                  Arbitration shall be initiated through the American
                  Arbitration Association (
                  <span className="font-black">“AAA”</span>), an established
                  alternative dispute resolution provider (
                  <span className="font-black">“ADR Provider”</span>) that
                  offers arbitration as set forth in this section. If AAA is not
                  available to arbitrate, the parties shall agree to select an
                  alternative ADR Provider. The rules of the ADR Provider shall
                  govern all aspects of the arbitration, including but not
                  limited to the method of initiating and/or demanding
                  arbitration, except to the extent such rules are in conflict
                  with the Terms. The AAA Consumer Arbitration Rules (
                  <span className="font-black">“Arbitration Rules”</span>)
                  governing the arbitration are available online at www.adr.org
                  or by calling the AAA at 1-800-778-7879. The arbitration shall
                  be conducted by a single, neutral arbitrator. Any claims or
                  disputes where the total amount of the award sought is less
                  than Ten Thousand U.S. Dollars (US $10,000) may be resolved
                  through binding non-appearance-based arbitration, at the
                  option of the party seeking relief. For claims or disputes
                  where the total amount of the award sought is Ten Thousand
                  U.S. Dollars (US $10,000) or more, the right to a hearing will
                  be determined by the Arbitration Rules. Any hearing will be
                  held in a location within 100 miles of Your residence, within
                  the United States, and unless the parties agree otherwise. Any
                  judgment on the award rendered by the arbitrator may be
                  entered in any court of competent jurisdiction. If the
                  arbitrator grants You an award that is greater than the last
                  settlement offer that the Company made to You prior to the
                  initiation of arbitration, the Company will pay You the
                  greater of the award or $2,500. Each party shall bear its own
                  costs (including attorney’s fees) and disbursements arising
                  out of the arbitration and shall pay an equal share of the
                  fees and costs of the ADR Provider.
                </p>

                <p className="mb-6">
                  (d){" "}
                  <span className="italic">
                    Additional Rules for Non-Appearance Based Arbitration
                  </span>
                  . If non-appearance based arbitration is elected, the
                  arbitration shall be conducted by telephone, online and/or
                  based solely on written submissions; the specific manner shall
                  be chosen by the party initiating the arbitration. The
                  arbitration shall not involve any personal appearance by the
                  parties or witnesses unless otherwise agreed by the parties.
                </p>

                <p className="mb-6">
                  (e) <span className="italic">Time Limits</span>. If You or the
                  Company pursue arbitration, the arbitration action must be
                  initiated and/or demanded within the statute of limitations
                  (i.e., the legal deadline for filing a claim) and within any
                  deadline imposed under the AAA Rules for the pertinent claim.
                </p>

                <p className="mb-6">
                  (f) <span className="italic">Authority of Arbitrator</span>.
                  If arbitration is initiated, the arbitrator will decide the
                  rights and liabilities, if any, of You and the Company, and
                  the dispute will not be consolidated with any other matters or
                  joined with any other cases or parties. The arbitrator shall
                  have the authority to grant motions dispositive of all or part
                  of any claim. The arbitrator shall have the authority to award
                  monetary damages, and to grant any non-monetary remedy or
                  relief available to an individual under applicable law, the
                  AAA Rules, and the Terms. The arbitrator shall issue a written
                  award and statement of decision describing the essential
                  findings and conclusions on which the award is based,
                  including the calculation of any damages awarded. The
                  arbitrator has the same authority to award relief on an
                  individual basis that a judge in a court of law would have.
                  The award of the arbitrator is final and binding upon You and
                  the Company.
                </p>

                <p className="mb-6">
                  (g) <span className="italic">Waiver of Jury Trial</span>. THE
                  PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS
                  TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY,
                  instead electing that all claims and disputes shall be
                  resolved by arbitration under this Arbitration Agreement.
                  Arbitration procedures are typically more limited, more
                  efficient and less costly than rules applicable in a court and
                  are subject to very limited review by a court. In the event
                  any litigation should arise between You and the Company in any
                  state or federal court in a suit to vacate or enforce an
                  arbitration award or otherwise, YOU AND THE COMPANY WAIVE ALL
                  RIGHTS TO A JURY TRIAL, instead electing that the dispute be
                  resolved by a judge.
                </p>

                <p className="mb-6">
                  (h){" "}
                  <span className="italic">
                    Waiver of Class or Consolidated Actions
                  </span>
                  . ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION
                  AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL
                  BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE
                  CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR
                  CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.
                </p>

                <p className="mb-6">
                  (i) <span className="italic">Confidentiality</span>. All
                  aspects of the arbitration proceeding, including but not
                  limited to the award of the arbitrator and compliance
                  therewith, shall be strictly confidential. The parties agree
                  to maintain confidentiality unless otherwise required by law.
                  This paragraph shall not prevent a party from submitting to a
                  court of law any information necessary to enforce this
                  Agreement, to enforce an arbitration award, or to seek
                  injunctive or equitable relief.
                </p>

                <p className="mb-6">
                  (j) <span className="italic">Severability</span>. If any part
                  or parts of this Arbitration Agreement are found under the law
                  to be invalid or unenforceable by a court of competent
                  jurisdiction, then such specific part or parts shall be of no
                  force and effect and shall be severed and the remainder of the
                  Agreement shall continue in full force and effect.
                </p>

                <p className="mb-6">
                  (k) <span className="italic">Right to Waive</span>. Any or all
                  of the rights and limitations set forth in this Arbitration
                  Agreement may be waived by the party against whom the claim is
                  asserted. Such waiver shall not waive or affect any other
                  portion of this Arbitration Agreement.
                </p>

                <p className="mb-6">
                  (l) <span className="italic">Survival of Agreement</span>.
                  This Arbitration Agreement will survive the termination of
                  Your relationship with Company.
                </p>

                <p className="mb-6">
                  (m) <span className="italic">Small Claims Court</span>.
                  Notwithstanding the foregoing, either You or the Company may
                  bring an individual action in small claims court.
                </p>

                <p className="mb-6">
                  (n) <span className="italic">Emergency Equitable Relief</span>
                  . Notwithstanding the foregoing, either party may seek
                  emergency equitable relief before a state or federal court to
                  maintain the status quo pending arbitration. A request for
                  interim measures shall not be deemed a waiver of any other
                  rights or obligations under this Arbitration Agreement.
                </p>

                <p className="mb-6">
                  (o){" "}
                  <span className="italic">
                    Claims Not Subject to Arbitration
                  </span>
                  . Notwithstanding the foregoing, claims of defamation,
                  violation of the Computer Fraud and Abuse Act, and
                  infringement or misappropriation of the other party’s patent,
                  copyright, trademark or trade secrets shall not be subject to
                  this Arbitration Agreement.
                </p>

                <p className="mb-6">
                  (p) <span className="italic">Courts</span>. In any
                  circumstances where the foregoing Arbitration Agreement
                  permits the parties to litigate in court, the parties hereby
                  agree to submit to the personal jurisdiction of the courts
                  located within Miami Dade County, Florida, for such purpose.
                </p>

                <p className="mb-6">
                  <span className="font-black">9.3 Assumption of Risks</span>.
                  You understand and acknowledge that a lack of use or public
                  interest in the creation and development of software systems
                  rooted in network effects could negatively impact the
                  development of the Application, and therefore the potential
                  utility or value of the Application. You further agree and
                  acknowledge that the regulatory regime governing blockchain
                  technologies, is uncertain, and new regulations or policies
                  may materially and/or adversely affect the development of the
                  Application, and therefore the potential utility or value of
                  the Application.
                </p>

                <p className="mb-6">
                  <span className="font-black">
                    9.4 Electronic Communications
                  </span>
                  . The communications between You and Company use electronic
                  means, whether You use the Application or send us emails, or
                  whether Company posts notices on the Application or
                  communicates with You via email. For contractual purposes, You
                  (a) consent to receive communications from Company in an
                  electronic form (whether via text or email); and (b) agree
                  that all terms and conditions, agreements, notices,
                  disclosures, and other communications that Company provides to
                  You electronically satisfy any legal requirement that such
                  communications would satisfy if it were in a hardcopy writing.
                  The foregoing does not affect Your non-waivable rights.
                </p>

                <p className="mb-6">
                  <span className="font-black">9.5 Entire Terms</span>. These
                  Terms constitute the entire agreement between You and Us
                  regarding the use of the Application. Our failure to exercise
                  or enforce any right or provision of these Terms shall not
                  operate as a waiver of such right or provision. The section
                  titles in these Terms are for convenience only and have no
                  legal or contractual effect. If any provision of these Terms
                  is, for any reason, held to be invalid or unenforceable, the
                  other provisions of these Terms will be unimpaired and the
                  invalid or unenforceable provision will be deemed modified so
                  that it is valid and enforceable to the maximum extent
                  permitted by law. These Terms, and Your rights and obligations
                  herein, may not be assigned, subcontracted, delegated, or
                  otherwise transferred by You without Company’s prior written
                  consent, and any attempted assignment, subcontract,
                  delegation, or transfer in violation of the foregoing will be
                  null and void. Company may freely assign these Terms. The
                  terms and conditions set forth in these Terms shall be binding
                  upon assignees.
                </p>

                <p className="mb-6">
                  <span className="font-black">
                    9.6 Copyright/Trademark Information
                  </span>
                  . Copyright © 2022 Generos, Inc. All rights reserved. All
                  trademarks, logos and service marks (
                  <span className="font-black">“Marks”</span>) displayed on the
                  Application and the Site are our property or the property of
                  other third parties. You are not permitted to use these Marks
                  without our prior written consent or the consent of such third
                  party which may own the Marks.
                </p>

                <p className="mb-6">
                  <span className="font-black">9.7 Suggestions</span>. You
                  acknowledge and agree that if You provide any comments,
                  questions, suggestions, ideas, and/or feedback (collectively,{" "}
                  <span className="font-black">“Suggestions”</span>) concerning
                  the Site and/or Application, We will be entitled to use the
                  information without restriction. You hereby irrevocably assign
                  to Us all right, title, and interest in, and to the
                  Suggestions and agree to provide Us any assistance We require
                  to document, perfect, and maintain our rights in the
                  Suggestions.
                </p>

                <p className="mb-6">
                  <span className="font-black">9.8 Contact Information</span>.
                  You may contact us at:
                  <div className="flex flex-col mt-3">
                    <div>Generos, Inc.</div>
                    <div>Email: info@generos.io</div>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default TOS;
