import React from "react";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import Footer from "../partials/Footer";
import { ReactComponent as GenerosityIcon } from "../assets/icons/values/generosity.svg";
import { ReactComponent as ConnectionIcon } from "../assets/icons/values/connection.svg";
import { ReactComponent as CollectiveActionIcon } from "../assets/icons/values/collective-action.svg";
import { ReactComponent as ValueCreationIcon } from "../assets/icons/values/value-creation.svg";
import { ReactComponent as DigitalOwnershipIcon } from "../assets/icons/values/digital-ownership.svg";
import { ReactComponent as EconomicEmpowermentIcon } from "../assets/icons/values/economic-empowerment.svg";

function CommunityValues() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        <section>
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-left">
              <div className="max-w-3xl mx-auto text-center pb-12">
                <div
                  className="text-white md:text-3xl md:leading-normal h4 mt-28"
                  data-aos="fade-up"
                >
                  Community Values
                </div>
              </div>
              <div className="flex flex-wrap md:justify-between justify-between mb-10 md:mb-20">
                <div
                  data-aos="fade-down"
                  className="mb-3 p-6"
                  data-aos-delay="400"
                >
                  <GenerosityIcon className="w-16 mb-4 text-primary-1 fill-current" />
                </div>
                <div
                  data-aos="fade-down"
                  className="mb-3 p-6"
                  data-aos-delay="800"
                >
                  <ConnectionIcon className="w-16 mb-4 text-primary-1 fill-current" />
                </div>
                <div
                  data-aos="fade-down"
                  className="mb-3 p-6"
                  data-aos-delay="1200"
                >
                  <CollectiveActionIcon className="w-16 mb-4 text-primary-1 fill-current" />
                </div>
                <div
                  data-aos="fade-down"
                  className="mb-3 p-6"
                  data-aos-delay="1600"
                >
                  <ValueCreationIcon className="w-16 mb-4 text-primary-1 fill-current" />
                </div>
                <div
                  data-aos="fade-down"
                  className="mb-3 p-6"
                  data-aos-delay="2000"
                >
                  <DigitalOwnershipIcon className="w-16 mb-4 text-primary-1 fill-current" />
                </div>
                <div
                  data-aos="fade-down"
                  className="mb-3 p-6"
                  data-aos-delay="2400"
                >
                  <EconomicEmpowermentIcon className="w-16 mb-4 text-primary-1 fill-current" />
                </div>
              </div>

              <div
                className="flex flex-col w-full mb-20"
                data-aos="fade-up"
                data-aos-delay="2800"
              >
                <div className="mb-20">
                  <div className="flex w-full items-center justify-center">
                    <GenerosityIcon className="w-36 mb-4 text-lilac-500 fill-current" />
                  </div>

                  <div className="text-3xl font-bold mb-2 text-white">
                    Generosity
                  </div>
                  <p className="text-xl text-white font-thin mb-10">
                    Generosity is at the heart of everything we do. It's about
                    freely giving, sharing resources, and supporting one another
                    without expectation of immediate return. In our community,
                    generosity is a superpower that fuels collective growth and
                    enables us to create a positive ripple effect in the world.
                  </p>
                </div>

                <div className="mb-20">
                  <div className="flex w-full items-center justify-center">
                    <ConnectionIcon className="w-36 mb-4 text-lilac-500 fill-current" />
                  </div>

                  <div className="text-3xl font-bold mb-2 text-white">
                    Connection
                  </div>
                  <p className="text-xl text-white font-thin mb-10">
                    We emphasize the importance of building strong connections,
                    trust, and collaboration among our members. This is the
                    lifeblood of our community, fostering unity, understanding,
                    and a sense of belonging. Through meaningful connections, we
                    strengthen our bonds and amplify our collective impact.
                  </p>
                </div>

                <div className="mb-20">
                  <div className="flex w-full items-center justify-center">
                    <CollectiveActionIcon className="w-36 mb-4 text-lilac-500 fill-current" />
                  </div>

                  <div className="text-3xl font-bold mb-2 text-white">
                    Collective Action
                  </div>
                  <p className="text-xl text-white font-thin mb-10">
                    We believe that true change happens when we come together as
                    a collective force. By joining hands and working
                    collaboratively, we can amplify our impact and tackle larger
                    societal challenges. Our community embraces the power of
                    collective action, encouraging collaboration, cooperation,
                    and shared responsibility.
                  </p>
                </div>

                <div className="mb-20">
                  <div className="flex w-full items-center justify-center">
                    <ValueCreationIcon className="w-36 mb-4 text-lilac-500 fill-current" />
                  </div>

                  <div className="text-3xl font-bold mb-2 text-white">
                    Value Creation
                  </div>
                  <p className="text-xl text-white font-thin mb-10">
                    Value creation lies at the core of our community's ethos,
                    representing the transformation of resources into valuable
                    outcomes through action. It's about leveraging our
                    collective gifts and creativity to produce meaningful
                    contributions that unlock our full potential and co-creates
                    a world filled with abundance and prosperity.
                  </p>
                </div>

                <div className="mb-20">
                  <div className="flex w-full items-center justify-center">
                    <DigitalOwnershipIcon className="w-36 mb-4 text-lilac-500 fill-current" />
                  </div>

                  <div className="text-3xl font-bold mb-2 text-white">
                    Digital Ownership
                  </div>
                  <p className="text-xl text-white font-thin mb-10">
                    In the digital realm, ownership takes on a new dimension. We
                    value the concept of digital ownership, which goes beyond
                    mere possession. It's about empowering individuals to have
                    control over their digital assets, data, and identity. By
                    promoting digital ownership, we foster a sense of autonomy,
                    privacy, and empowerment within our community.
                  </p>
                </div>

                <div className="mb-20">
                  <div className="flex w-full items-center justify-center">
                    <EconomicEmpowermentIcon className="w-36 mb-4 text-lilac-500 fill-current" />
                  </div>

                  <div className="text-3xl font-bold mb-2 text-white">
                    Economic Empowerment
                  </div>
                  <p className="text-xl text-white font-thin mb-10">
                    We are passionate about economic empowerment. We believe in
                    creating economic opportunities that are accessible,
                    inclusive, and equitable for all. Through our platform, we
                    strive to provide economic empowerment to individuals,
                    enabling them to participate in the digital economy and find
                    new avenues for growth and prosperity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default CommunityValues;
