import { gql } from "@apollo/client";

const JOIN_WAITLIST_MUTATION = gql`
  mutation joinWaitlist(
    $firstName: String!
    $lastName: String!
    $email: String!
    $why: String!
  ) {
    joinWaitlist(
      firstName: $firstName
      lastName: $lastName
      email: $email
      why: $why
    )
  }
`;

export { JOIN_WAITLIST_MUTATION };
