import React from "react";
import { useHistory } from "react-router-dom";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import PageIllustration from "../partials/PageIllustration";

import ContentContainer from "../components/cards/content-container";
import CardActions from "../components/cards/card-actions";
import { ReactComponent as Sensei } from "../assets/sensei/staff.svg";

import Button from "../components/Button";

const JoinWaitlistConfirmation = () => {
  const history = useHistory();
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        <section>
          <div className="max-w-xl mx-auto px-4 sm:px-6 relative mt-24">
            <ContentContainer className="px-6 text-white text-xl text-center">
              <div className="flex flex-col items-center text-center justify-center ">
                <div className="mb-6 font-semibold">
                  <span className="block">
                    Thank you for joining the Generos waitlist!
                  </span>
                </div>
                <Sensei className="md:w-80 w-80 md:px-0 px-14 mb-6" />

                <div className="mb-6">
                  <span className="block">
                    Watch your email for your early access invite.
                  </span>
                </div>
              </div>
            </ContentContainer>
            <CardActions>
              <Button
                text="Got It!"
                className="m-3"
                onClick={() => history.push("/")}
              />
            </CardActions>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
};

export default JoinWaitlistConfirmation;
