import React from "react"
import classnames from "classnames"

const ContentContainer = ({
  children,
  className,
  pt = "pt-12",
  pb = "pb-8",
  rounded = "rounded-t-lg",
}) => {
  const classes = classnames({
    [className]: !!className,
    [`flex flex-col items-center bg-primary-4 ${rounded} ${pt} ${pb} px-6 w-full`]: true,
  })

  return <div className={classes}>{children}</div>
}

export default ContentContainer
