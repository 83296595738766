import React from "react";
import { ReactComponent as GencoinIcon } from "../assets/icons/gencoin/generated.svg";
import { ReactComponent as CommunityIcon } from "../assets/icons/community.svg";
import { ReactComponent as ImpactIcon } from "../assets/icons/impact.svg";
// import { ReactComponent as GLogo } from "../assets/g-logo.svg";

function Process() {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 items-center">
        <div className="">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h2 className="h2 mb-4" data-aos="fade-up">
              The Generos Method
            </h2>
            <p
              className="text-xl text-lilac-500 font-semibold mb-6"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <span className="block">
                Generos helps you
                <span className="font-rubik  text-primary-1">
                  {" leverage "}
                </span>
                a human virtue we can all tap into...
              </span>

              <span className="block">generosity!</span>
            </p>
          </div>
          {/* 
          <div className="flex justify-center items-center ">
            <GLogo className="absolute fill-current text-white w-20" />

            <svg viewBox="0 0 540 520" xmlns="http://www.w3.org/2000/svg">
              <g className="fill-current text-purple-600">
                <circle className="radiate-pulse" cx="270" cy="260" r="64" />
                <circle
                  className="radiate-pulse radiate-pulse-1"
                  cx="270"
                  cy="260"
                  r="64"
                />
                <circle
                  className="radiate-pulse radiate-pulse-2"
                  cx="270"
                  cy="260"
                  r="64"
                />
                <circle
                  className="radiate-pulse radiate-pulse-3"
                  cx="270"
                  cy="260"
                  r="64"
                />
              </g>
            </svg>
          </div> */}

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-8 md:grid-cols-3 lg:gap-16 items-start md:max-w-none">
            {/* 1st item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
            >
              <div
                aria-hidden="true"
                className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block"
                style={{
                  width: "calc(100% - 32px)",
                  left: "calc(50% + 48px)",
                  top: "32px",
                }}
                data-aos="fade-in"
                data-aos-delay="200"
              ></div>
              <CommunityIcon className="w-16 h-16 mb-4 text-primary-1 fill-current" />

              <h4 className="h4 mb-2 text-white">Spread</h4>
              <p className="text-lg font-medium text-lilac-600 text-center">
                Spread <span className="italic">{" social "}</span> generosity
                with a community of like hearted humans
              </p>
            </div>

            {/* 2nd item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div
                aria-hidden="true"
                className="absolute h-1 border-t border-dashed border-gray-700 hidden md:block"
                style={{
                  width: "calc(100% - 32px)",
                  left: "calc(50% + 48px)",
                  top: "32px",
                }}
                data-aos="fade-in"
                data-aos-delay="400"
              ></div>
              <GencoinIcon className="w-16 h-16 mb-4 text-primary-1 fill-current" />
              <h4 className="h4 mb-2 text-white">Earn</h4>
              <p className="text-lg font-medium text-lilac-600 text-center">
                Earn a virtual currency that can be converted to real cash.
              </p>
            </div>

            {/* 3rd item */}
            <div
              className="relative flex flex-col items-center"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <ImpactIcon className="w-16 h-16 mb-4 fill-current text-primary-1" />

              <h4 className="h4 mb-2 text-white">Grow</h4>
              <p className="text-lg font-medium text-lilac-600 text-center">
                <span className="block">
                  Grow your philanthropic impact and ROG
                </span>
                <span className="block italic">(return on generosity)</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Process;
