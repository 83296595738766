import React, { useState, useEffect } from "react";

const Sensei = ({ className }) => {
  const [rightEye, setRightEye] = useState("open");
  const [leftEye, setLeftEye] = useState("open");
  const [rightEyebrow, setRightEyebrow] = useState("normal");
  const [leftEyebrow, setLeftEyebrow] = useState("normal");

  const RightEyeClosed = () => (
    <path
      d="M661.213 372.91C662.111 371.877 663.683 371.742 664.806 372.55C668.039 374.751 671.495 376.592 675.088 378.029C687.751 383.058 701.717 382.609 713.976 376.726C716.401 375.559 718.826 373.853 720.891 371.787C721.834 370.844 723.316 370.754 724.349 371.562C724.529 371.697 724.708 371.832 724.887 372.012C727.716 374.391 729.018 377.041 729.243 379.78C729.872 386.965 723.047 394.509 716.401 397.742C704.142 403.624 690.221 403.983 677.513 399.044C654.836 390.288 659.596 374.751 661.213 372.954V372.91Z"
      fill="#575564"
    />
  );

  const RightEyeOpen = () => (
    <>
      <path
        d="M659.671 376.947C659.671 381.513 660.592 385.827 662.31 389.806C667.545 401.952 679.985 402.832 694.477 402.832C708.09 402.832 719.901 402.958 725.597 391.984C727.943 387.46 729.283 382.35 729.283 376.947C729.283 358.434 713.702 343.439 694.477 343.439C675.252 343.439 659.671 358.434 659.671 376.947Z"
        fill="#32313F"
      />
      <path
        d="M682.624 376.654C688.198 376.654 692.718 372.135 692.718 366.56C692.718 360.985 688.198 356.466 682.624 356.466C677.049 356.466 672.529 360.985 672.529 366.56C672.529 372.135 677.049 376.654 682.624 376.654Z"
        fill="#D4D4DC"
      />
    </>
  );

  const LeftEyeClosed = () => (
    <path
      d="M452.491 372.595C451.572 371.539 449.965 371.401 448.817 372.228C445.51 374.478 441.975 376.361 438.301 377.831C425.35 382.974 411.067 382.515 398.53 376.499C396.05 375.305 393.57 373.56 391.458 371.447C390.493 370.483 388.978 370.391 387.921 371.218C387.738 371.355 387.554 371.493 387.371 371.677C384.478 374.111 383.146 376.82 382.916 379.622C382.273 386.969 389.253 394.685 396.05 397.991C408.587 404.007 422.824 404.375 435.821 399.323C459.013 390.368 454.144 374.478 452.491 372.641V372.595Z"
      fill="#575564"
    />
  );

  const LeftEyeOpen = () => (
    <>
      <path
        d="M454.981 376.947C454.981 381.513 454.059 385.827 452.342 389.806C447.106 401.952 434.666 402.832 420.174 402.832C406.562 402.832 394.75 402.958 389.054 391.984C386.708 387.46 385.368 382.35 385.368 376.947C385.368 358.434 400.949 343.439 420.174 343.439C439.399 343.439 454.981 358.434 454.981 376.947Z"
        fill="#32313F"
      />
      <path
        d="M411.336 376.654C416.911 376.654 421.431 372.135 421.431 366.56C421.431 360.985 416.911 356.466 411.336 356.466C405.762 356.466 401.242 360.985 401.242 366.56C401.242 372.135 405.762 376.654 411.336 376.654Z"
        fill="#D4D4DC"
      />
    </>
  );

  const RightEye = () => {
    if (rightEye === "open") {
      return <RightEyeOpen />;
    } else {
      return <RightEyeClosed />;
    }
  };

  const LeftEye = () => {
    if (leftEye === "open") {
      return <LeftEyeOpen />;
    } else {
      return <LeftEyeClosed />;
    }
  };

  const RightEyebrowNormal = () => (
    <>
      <path
        d="M808.027 238.979C810.498 238.057 813.095 240.026 812.885 242.623C810.289 273.743 779.419 307.125 711.315 303.607C659.671 300.926 642.121 295.02 636.341 291.879C634.54 290.916 633.744 288.779 634.289 286.811C637.053 276.926 649.2 245.638 693.053 242.539C724.425 238.266 771.126 252.717 808.069 239.02L808.027 238.979Z"
        fill="#EEEFF3"
      />
      <path
        d="M636.3 291.879C642.122 295.063 659.63 300.968 711.273 303.607C779.378 307.125 810.247 273.743 812.844 242.623C812.97 241.115 812.132 239.817 810.959 239.188C755.42 303.691 684.132 243.209 634.792 290.33C635.169 290.958 635.63 291.502 636.3 291.838V291.879Z"
        fill="#DBDBDB"
      />
    </>
  );

  const RightEyebrowRaised = () => (
    <>
      <path
        d="M813.514 261.955C816.139 261.704 818.138 264.278 817.264 266.732C806.701 296.12 768.244 320.375 703.371 299.35C654.18 283.394 638.757 273.147 633.987 268.617C632.497 267.22 632.281 264.951 633.316 263.191C638.545 254.358 658.375 227.28 701.537 235.636C732.945 239.629 774.315 265.674 813.544 262.006L813.514 261.955Z"
        fill="#EEEFF3"
      />
      <path
        d="M633.947 268.607C638.747 273.189 654.129 283.425 703.331 299.34C768.204 320.365 806.661 296.11 817.224 266.722C817.736 265.298 817.262 263.827 816.292 262.917C745.951 310.847 692.746 233.975 632.892 266.72C633.093 267.424 633.397 268.07 633.958 268.567L633.947 268.607Z"
        fill="#DBDBDB"
      />
    </>
  );

  const LeftEyebrowNormal = () => (
    <>
      <path
        d="M306.666 238.979C304.195 238.057 301.598 240.026 301.808 242.623C304.405 273.743 335.274 307.125 403.378 303.607C455.022 300.926 472.572 295.02 478.352 291.879C480.153 290.916 480.949 288.779 480.404 286.811C477.64 276.926 465.493 245.638 421.64 242.539C390.268 238.266 343.567 252.717 306.625 239.02L306.666 238.979Z"
        fill="#EEEFF3"
      />
      <path
        d="M478.059 291.879C472.238 295.063 454.73 300.968 403.086 303.607C334.981 307.125 304.112 273.743 301.515 242.623C301.39 241.115 302.227 239.817 303.4 239.188C358.939 303.691 430.227 243.209 479.567 290.33C479.19 290.958 478.73 291.502 478.059 291.838V291.879Z"
        fill="#DBDBDB"
      />
    </>
  );

  const LeftEyebrowRaised = () => (
    <>
      <path
        d="M301.174 261.917C298.549 261.667 296.55 264.24 297.424 266.694C307.987 296.082 346.444 320.337 411.318 299.312C460.508 283.357 475.931 273.11 480.701 268.58C482.192 267.183 482.408 264.914 481.372 263.153C476.144 254.32 456.313 227.242 413.152 235.599C381.743 239.592 340.373 265.637 301.145 261.968L301.174 261.917Z"
        fill="#EEEFF3"
      />
      <path
        d="M480.419 268.656C475.619 273.238 460.237 283.474 411.036 299.389C346.162 320.414 307.705 296.159 297.142 266.771C296.631 265.347 297.104 263.876 298.074 262.966C368.415 310.896 421.62 234.025 481.475 266.769C481.273 267.474 480.969 268.119 480.408 268.616L480.419 268.656Z"
        fill="#DBDBDB"
      />
    </>
  );

  const RightEyebrow = () => {
    if (rightEyebrow === "normal") {
      return <RightEyebrowNormal />;
    } else {
      return <RightEyebrowRaised />;
    }
  };

  const LeftEyebrow = () => {
    if (leftEyebrow === "normal") {
      return <LeftEyebrowNormal />;
    } else {
      return <LeftEyebrowRaised />;
    }
  };

  useEffect(() => {
    const blink = () => {
      // Close the eyes
      setRightEye("closed");
      setLeftEye("closed");

      // After a random delay between 100ms and 300ms, open the eyes
      const delay = Math.random() * 250 + 100; // Random delay between 50ms and 300ms
      setTimeout(() => {
        setRightEye("open");
        setLeftEye("open");
      }, delay); // Keep the eye opening duration constant at 100ms

      // Schedule the next blink with a random interval
      const interval = Math.random() * 4000 + 1500; // Random interval between
      blinkTimeout = setTimeout(blink, interval);
    };

    let blinkTimeout = setTimeout(blink, 0); // Start the blinking immediately

    return () => clearTimeout(blinkTimeout);
  }, []);

  useEffect(() => {
    const raiseEyebrows = () => {
      // Raise the eyebrows
      setRightEyebrow("raised");
      setLeftEyebrow("raised");

      // After a random delay between
      const delay = Math.random() * 8000 + 7000; // Random delay between
      setTimeout(() => {
        setRightEyebrow("normal");
        setLeftEyebrow("normal");
      }, delay);

      // Schedule the next eyebrow movement with a random interval
      const interval = Math.random() * 8000 + 7000; // Random interval between
      blinkTimeout = setTimeout(raiseEyebrows, interval);
    };

    let blinkTimeout = setTimeout(raiseEyebrows, 0); // Start the eyebrow movement immediately

    return () => clearTimeout(blinkTimeout);
  }, []);

  const [pawOffset, setPawOffset] = useState(0);

  useEffect(() => {
    const floatPaw = () => {
      // Generate a floating offset between -5 and 5 with a sinusoidal pattern
      const offset = Math.sin(Date.now() / 1500) * 30;
      setPawOffset(offset);

      // Schedule the next frame
      requestAnimationFrame(floatPaw);
    };

    let animationFrame = requestAnimationFrame(floatPaw);

    return () => cancelAnimationFrame(animationFrame);
  }, []);

  const Paw = () => (
    <g transform={`translate(0 ${pawOffset})`}>
      <path
        d="M300.132 955.984C302.598 944.944 297.056 934.311 287.755 932.233C278.454 930.156 268.914 937.421 266.449 948.461C263.983 959.501 269.525 970.134 278.826 972.212C288.127 974.289 297.666 967.024 300.132 955.984Z"
        fill="#252530"
      />
      <path
        d="M766.604 937.825C726.813 920.401 701.389 951.438 691.672 966.851L690.164 961.071C677.054 934.684 647.4 927.731 625.452 926.474C610.583 925.595 596.929 918.349 587.756 906.579C572.971 887.605 550.102 877.637 527.442 873.197C498.206 867.501 457.997 875.25 430.73 892.213C390.982 916.883 353.579 916.59 334.479 913.951C333.097 913.742 331.673 913.616 330.291 913.532C311.526 911.689 290.458 918.098 281.076 935.773C273.495 949.344 273.872 966.642 281.998 979.71C284.134 983.103 286.898 986.035 289.956 988.631C302.647 1001.95 322.207 1007.98 342.437 1009.28C360.95 1011.08 374.772 1006.31 385.788 1000.44L388.175 999.731C417.285 992.904 448.447 1011.25 448.447 1059.84C448.447 1059.84 448.196 1074.41 452.552 1092.76C463.442 1138.41 508.719 1164.71 554.081 1152.82C571.505 1148.25 585.117 1141.93 594.374 1132.8L595.672 1131.46C595.672 1131.46 668.845 1152.9 693.515 1090.29L695.483 1084.76C707.965 1084.88 734.436 1078.85 759.651 1032.69C764.886 1023.1 799.776 950.433 766.562 937.867L766.604 937.825Z"
        fill="#B5B4C0"
      />
      <path
        opacity="0.75"
        d="M687.232 988.003C667.253 959.689 635.882 955.081 613.348 956.129C597.348 956.841 580.803 952.317 569.536 940.966C503.777 874.705 413.683 941.469 413.683 941.469C413.683 941.469 429.139 980.924 393.704 998.683C393.704 998.683 450.877 988.966 448.741 1066.7C446.814 1136.27 499.212 1155.21 535.4 1154.7C577.704 1154.12 595.756 1131.33 595.756 1131.33C595.756 1131.33 676.175 1151.56 695.567 1084.63C727.609 1084.63 794.792 998.265 767.441 965.385C734.059 927.563 694.311 990.097 694.311 990.097"
        fill="#9A99A7"
      />
      <path
        opacity="0.35"
        d="M498.249 1013.8C477.348 1019.46 459.547 996.841 471.191 978.579C476.929 969.532 486.94 964.212 494.06 963.5C515.338 961.406 539.631 965.972 557.557 977.783C568.992 985.322 582.856 988.045 596.51 987.123C622.646 985.364 636.007 998.726 636.007 998.726C636.007 998.726 597.013 1028.76 592.992 1059.96C575.903 1009.36 528.364 1005.64 498.291 1013.76L498.249 1013.8Z"
        fill="#83838E"
      />
      <path
        d="M606.269 1084.67C595.756 1047.44 555.17 1046.52 555.17 1046.52C538.458 1045.64 506.29 1054.56 493.013 1084.67C493.013 1084.67 556.719 1029.09 606.269 1084.67Z"
        fill="#B5B4C0"
      />
      <path
        d="M704.321 1046.52C706.792 1032.74 701.096 1007.31 667.756 1005.43C621.85 1002.79 597.348 1049.15 597.348 1049.15C597.348 1049.15 600.992 992.401 650.08 983.647C716.803 971.752 704.363 1046.56 704.363 1046.56L704.321 1046.52Z"
        fill="#B5B4C0"
      />
      <path
        d="M590.046 1096.63C593.578 1084.26 584.324 1070.77 569.377 1066.5C554.43 1062.24 539.449 1068.8 535.917 1081.17C532.385 1093.53 541.639 1107.02 556.586 1111.29C571.533 1115.56 586.513 1108.99 590.046 1096.63Z"
        fill="#32313F"
      />
      <path
        d="M694.03 1006.1C696.192 995.703 687.572 985.118 674.776 982.457C661.98 979.795 649.855 986.065 647.692 996.46C645.53 1006.86 654.15 1017.44 666.946 1020.1C679.742 1022.76 691.868 1016.49 694.03 1006.1Z"
        fill="#32313F"
      />
      <path
        opacity="0.75"
        d="M305.536 950.055C320.74 936.024 355.798 923.71 389.431 936.443C423.106 949.176 431.274 994.244 370.038 1007.35C308.803 1020.46 278.269 977.28 305.536 950.055Z"
        fill="#9A99A7"
      />
      <path
        d="M617.619 1135.56C646.771 1125.68 641.745 1055.9 590.604 1060.13C596.049 1063.86 623.19 1093.55 594.457 1132.25L617.619 1135.56Z"
        fill="#77777F"
      />
      <path
        d="M689.577 1098.45C711.315 1056.61 701.933 1016.36 701.933 1016.36C701.933 1016.36 742.519 1029.59 721.535 1077.05C712.321 1095.81 689.577 1098.5 689.577 1098.5V1098.45Z"
        fill="#77777F"
      />
      <path
        d="M775.346 948.443C777.287 939.112 770.397 929.788 759.956 927.616C749.515 925.444 739.478 931.248 737.538 940.579C735.597 949.909 742.487 959.234 752.928 961.406C763.368 963.577 773.405 957.774 775.346 948.443Z"
        fill="#32313F"
      />
    </g>
  );

  return (
    <svg
      className={className}
      viewBox="0 0 1113 1435"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_655_3321)">
        <path
          d="M720.655 624.695C838.896 716.129 793.493 913.49 930.372 966.014C930.372 966.014 837.974 967.144 828.466 1078.26C807.65 1169.87 755.587 1027.25 755.587 1027.25L720.194 921.071L712.906 762.873L665.032 683.753L701.975 608.611L720.613 624.653L720.655 624.695Z"
          fill="#874435"
        />
        <path
          opacity="0.25"
          d="M725.263 752.611L733.975 884.381L730.079 950.768L755.587 1027.29L755.713 1027.63L817.744 1080.74L830.435 1063.02C835.461 1033.41 846.896 1012.8 860.341 998.516C772.299 954.412 787.922 821.972 725.263 752.695V752.611Z"
          fill="#472E33"
        />
        <path
          d="M879.734 989.762C891.378 981.26 900.299 986.747 918.561 983.857L921.87 970.244C927.399 969.406 933.472 966.935 933.472 966.935C914.917 959.815 888.865 941.386 876.132 929.532C855.106 935.773 790.813 961.7 779.043 1048.23L826.917 1080.86C826.917 1080.86 824.446 1073.99 835.922 1038.06C835.922 1038.06 845.053 1027.17 854.98 1012.92C862.603 1001.49 870.687 996.129 879.776 989.679L879.734 989.762Z"
          fill="#F3AE2C"
        />
        <path
          d="M819.21 963.459C801.032 981.511 784.655 1008.23 779.168 1047.65L783.231 1051.12L820.676 1076.67L845.08 1042.84C845.08 1042.84 841.367 1027.38 851.293 1013.13C851.419 1012.97 851.503 1012.84 851.629 1012.67C852.215 1011.75 852.843 1010.91 853.471 1010.03C854.142 1009.11 854.812 1008.23 855.482 1007.31C856.571 1005.89 857.702 1004.51 858.833 1003.21C859.461 1002.45 860.131 1001.7 860.801 1000.99C861.178 1000.57 861.555 1000.11 861.932 999.689C844.676 991.061 829.89 977.742 819.252 963.459H819.21Z"
          fill="#DD9624"
        />
        <path
          d="M491.63 1211.12C499.337 1292 530.289 1331.83 547.588 1348.13C554.122 1354.28 562.834 1357.43 571.755 1356.88C611.294 1354.49 738.456 1343.23 845.975 1294.68C979.587 1234.33 991.398 1165.89 979.796 1116C973.555 1089.11 953.953 1053.68 903.943 1037.64C871.105 1027.08 835.378 1028.51 803.462 1041.49C792.613 1045.89 781.137 1051.84 770.373 1059.88L666.457 1136.32L491.63 1211.08V1211.12Z"
          fill="#B5B4C0"
        />
        <path
          opacity="0.6"
          d="M966.127 1097.99C966.127 1097.99 956.806 1095.26 961.329 1086.8C953.916 1060.62 951.063 1052.8 903.985 1037.68C871.147 1027.12 835.42 1028.55 803.503 1041.53C792.655 1045.93 781.179 1051.88 770.415 1059.92L670.98 1133.09L635.504 1294.89C716.132 1288.57 922.145 1216.09 956.532 1121.18L966.127 1097.99Z"
          fill="#9A99A7"
        />
        <path
          d="M576.069 1204.21L540.174 1340.5C542.226 1342.81 544.153 1344.86 545.996 1346.62L588.551 1355.67C618.791 1353.2 669.43 1347.42 725.43 1334.35C691.168 1299.46 691.545 1212.55 691.545 1212.55L576.069 1204.21Z"
          fill="#F9D391"
        />
        <path
          d="M636.132 1210.12L635.462 1294.85C652.132 1293.55 674.75 1289.53 700.383 1283.16C691.462 1248.32 691.587 1212.5 691.587 1212.5L636.174 1210.12H636.132Z"
          fill="#E5B877"
        />
        <path
          d="M348.175 603.292L539.337 813.888L721.954 628.172L662.101 587.753L523.797 594.748L348.175 603.292Z"
          fill="#6E6D79"
        />
        <path
          d="M246.688 1037.38C157.683 1040.69 127.484 1089.49 119.4 1124.17C107.798 1174.01 119.652 1242.49 253.222 1302.85C360.698 1351.39 487.86 1362.66 527.399 1365.05C536.363 1365.59 545.075 1362.41 551.609 1356.25C568.907 1339.96 599.818 1300.09 607.525 1219.29"
          fill="#B5B4C0"
        />
        <path
          opacity="0.75"
          d="M436.886 1185.61L387.881 1084L244.384 1054.73C191.442 1053.72 168.615 1087.44 168.615 1087.44C137.662 1128.86 140.259 1238.18 406.227 1291.79C406.227 1291.79 431.734 1260.63 436.844 1185.61H436.886Z"
          fill="#9A99A7"
        />
        <path
          d="M667.462 1203.75C701.347 1273.78 620.593 1429.51 598.226 1433.82C575.86 1438.14 559.734 1421.8 559.734 1421.8C559.734 1421.8 613.347 1366.01 624.195 1301.84C635.043 1237.68 633.577 1208.07 633.577 1208.07L667.462 1203.75Z"
          fill="#472E33"
        />
        <path
          d="M641.577 1213.26C662.561 1256.74 612.383 1381.05 581.43 1414.43C577.451 1418.7 572.132 1421.76 566.352 1422.43C520.237 1427.96 508.802 1378.58 508.802 1378.58C508.802 1378.58 498.75 1381.43 507.001 1360.86C515.253 1340.3 530.75 1315.16 539.001 1289.57C547.252 1263.98 560.488 1220.13 576.027 1211.46C591.566 1202.79 639.985 1210.54 639.985 1210.54L641.577 1213.26Z"
          fill="#9D9CA8"
        />
        <path
          d="M542.101 1293.51L526.101 1331.21C526.101 1331.21 567.441 1329.03 617.661 1351.98C619.21 1348.38 620.76 1344.73 622.268 1341.05C564.634 1321.07 542.142 1293.51 542.142 1293.51H542.101Z"
          fill="#F9D391"
        />
        <path
          opacity="0.25"
          d="M540.928 1309.68C534.352 1330.07 509.472 1364.67 515.169 1391.6C516.006 1363.42 531.588 1363.08 545.284 1340.42C576.949 1289.24 559.483 1264.27 588.258 1218.12L578.415 1220.21C562.792 1240.61 552.656 1273.15 540.886 1309.72L540.928 1309.68Z"
          fill="black"
        />
        <path
          d="M605.346 1200.19C572.09 1210.33 558.31 1255.69 540.928 1309.68C533.849 1331.58 505.577 1368.11 516.97 1397.85C500.551 1398.77 476.886 1380.21 477.766 1366.52C468.048 1360.61 456.53 1357.76 441.284 1356.21C371.42 1349.22 367.357 1339.46 367.357 1339.46C367.357 1339.46 424.781 1291 435.546 1203.08L605.388 1200.19H605.346Z"
          fill="#F9D391"
        />
        <path
          d="M592.194 1206.68L545.744 1201.15L435.504 1203.04C431.022 1239.56 418.499 1269.22 405.389 1291.5C441.954 1299.29 492.467 1307.29 537.242 1320.02C538.456 1316.88 539.671 1313.49 540.886 1309.64C555.42 1263.27 567.985 1223.52 592.153 1206.68H592.194Z"
          fill="#E5B877"
        />
        <path
          d="M509.053 1221.76L487.65 1216.86C487.65 1216.86 490.289 1290.33 432.823 1355.29C435.546 1355.58 438.31 1355.88 441.284 1356.21C443.211 1356.42 445.054 1356.63 446.855 1356.84C506.373 1307.21 509.095 1221.76 509.095 1221.76H509.053Z"
          fill="#C5966A"
        />
        <path
          d="M208.783 1108.92C208.783 1108.92 197.097 1133.76 196.343 1148.3C196.343 1148.3 313.829 1145.91 416.154 1176.94C337.746 1104.11 208.783 1108.92 208.783 1108.92Z"
          fill="#83838E"
        />
        <path
          d="M376.74 1167.31C283.379 1117.59 177.117 1123.67 177.117 1123.67C185.159 1071.77 242.415 1024.36 242.415 1024.36C242.415 1024.36 354.122 1055.86 376.74 1167.31Z"
          fill="#9B543B"
        />
        <path
          d="M356.174 1157.34C299.588 1100.92 216.447 1093.26 187.546 1092.38C182.604 1102.35 178.793 1112.9 177.117 1123.67C177.117 1123.67 268.426 1118.47 356.174 1157.34Z"
          fill="#472E33"
        />
        <path
          d="M335.023 574.811C143.568 723.669 129.16 1177.7 556.467 1232.57C556.467 1232.57 605.849 1125.18 592.907 1056.44C579.965 987.71 499.42 989.176 499.42 989.176C499.42 989.176 391.065 871.564 415.232 695.145C396.929 663.816 379.63 637.302 365.85 617.24C347.295 590.224 335.023 574.852 335.023 574.852V574.811Z"
          fill="#AC6542"
        />
        <path
          d="M592.907 1056.44C579.965 987.71 499.42 989.176 499.42 989.176C499.42 989.176 391.065 871.564 415.232 695.146C411.63 688.988 408.07 683.041 404.551 677.261L336.447 611.041C221.599 734.936 206.395 1021.76 442.918 1122.5L477.598 1134.26C477.598 1134.26 527.944 1146.33 586.959 1143.02C593.828 1113.91 597.849 1082.45 592.949 1056.48L592.907 1056.44Z"
          fill="#9B543B"
        />
        <path
          d="M729.702 1000.4C772.844 894.852 744.069 772.926 706.205 713.91L674.959 674.287L541.305 807.941L395.253 659.041C395.253 659.041 327.483 809.868 463.022 898.622C598.561 987.375 625.284 982.642 625.284 982.642L729.66 1000.44L729.702 1000.4Z"
          fill="#9B543B"
        />
        <path
          d="M611.797 717.261C546.038 782.852 478.017 850.705 450.08 905.365L476.174 918.684C501.975 868.171 571.294 799.02 632.488 737.994C670.436 700.13 706.289 664.36 731.545 633.826C728.069 630.685 724.425 627.627 720.655 624.695L709.221 614.853C684.886 644.339 649.368 679.774 611.797 717.219V717.261Z"
          fill="#472E33"
        />
        <path
          d="M412.258 696.862C450.75 741.302 490.583 787.207 536.363 823.479L554.54 800.485C510.897 765.93 471.986 721.071 434.373 677.679C399.902 637.93 367.148 600.15 334.394 575.312C326.604 581.428 319.107 588.045 311.902 595.124C343.609 617.742 376.991 656.234 412.216 696.904L412.258 696.862Z"
          fill="#472E33"
        />
        <path
          d="M761.471 1026.98C761.471 1026.98 984.507 1004.2 948.32 1097.79C948.32 1097.79 763.084 1245.55 598.017 1209.07"
          fill="#AC6542"
        />
        <path
          opacity="0.65"
          d="M778.568 1025.37C792.684 1024.89 1026.79 1017.88 846.12 1108.31C846.12 1108.31 772.698 1142.22 694.091 1159.41C587.029 1177.79 720.501 1054.11 720.501 1054.11L761.366 1027.42L778.568 1025.37Z"
          fill="#9B543B"
        />
        <path
          d="M954.32 1094.93C944.812 1091.29 947.032 1078.03 938.403 1073.38C878.715 1123.45 735.734 1204.29 614.729 1192.15L597.682 1211.54C762.749 1248.02 954.32 1094.93 954.32 1094.93Z"
          fill="#472E33"
        />
        <path
          d="M586.582 1144.32L528.949 1035.21L548.676 1000.15C524.341 988.715 499.462 989.175 499.462 989.175C499.462 989.175 478.352 966.265 456.865 925.511L382.143 978.495C354.876 995.333 329.326 1046.6 340.425 1106.12C347.211 1142.56 371.713 1173.3 405.054 1189.47C447.65 1210.08 497.87 1225.07 556.467 1232.61C556.467 1232.61 575.231 1191.81 586.582 1144.4V1144.32Z"
          fill="#F3AE2C"
        />
        <path
          d="M548.677 1000.15C524.342 988.715 499.462 989.175 499.462 989.175C499.462 989.175 478.352 966.265 456.866 925.511L382.143 978.495C363.505 990.013 345.662 1017.66 339.798 1053.01C366.813 1080.74 400.866 1104.57 442.918 1122.45L477.599 1134.22C477.599 1134.22 527.358 1146.12 585.912 1143.02L528.949 1035.21L548.677 1000.15Z"
          fill="#DD9624"
        />
        <path
          d="M446.687 892.38C390.98 884.087 356.509 854.349 340.551 836.757C336.153 831.898 340.174 824.15 346.708 824.945C362.792 826.872 388.677 831.354 416.279 841.49C460.677 860.715 458.708 884.464 446.687 892.38Z"
          fill="#7C3F2C"
        />
        <path
          d="M569.703 1232.57C643.541 1232.57 703.399 1170.72 703.399 1094.43C703.399 1018.14 643.541 956.296 569.703 956.296C495.865 956.296 436.007 1018.14 436.007 1094.43C436.007 1170.72 495.865 1232.57 569.703 1232.57Z"
          fill="#704812"
        />
        <path
          opacity="0.75"
          d="M567.022 1203.25C629.221 1183.56 663.483 1116.55 643.546 1053.59C624.949 994.956 565.891 960.568 507.63 972.045C465.075 995.165 436.007 1041.24 436.007 1094.39C436.007 1134.85 452.844 1171.25 479.693 1196.5C505.996 1209.49 537.033 1212.76 567.022 1203.25Z"
          fill="#472E33"
        />
        <path
          d="M552.99 654.098C481.912 659.459 380.802 644.799 277.138 527.145C274.373 531.669 270.604 538.328 267.085 544.108C231.693 509.135 245.012 430.014 245.012 430.014C136.112 436.339 82.7507 366.601 82.7507 366.601L69.4314 381.679C68.0911 383.187 65.7037 383.145 64.4471 381.554C2.29021 303.439 18.9184 212.298 30.4367 176.821C21.264 176.821 9.32684 176.528 3.4211 176.402C1.4944 176.36 -0.0134495 174.601 0.279744 172.717C13.5572 90.1199 123.84 -18.0683 260.342 29.3452C260.007 19.2928 259.881 9.65934 259.839 4.25621C259.839 2.03632 262.017 0.444699 264.112 1.19862C357.431 34.2038 403.588 140.884 403.588 140.884C403.588 140.884 456.697 112.528 544.613 114.497H568.069C655.985 112.528 709.095 140.884 709.095 140.884C709.095 140.884 755.88 32.7797 850.456 0.570353C851.628 0.151506 852.885 1.07297 852.885 2.28763C852.885 7.06248 852.759 17.9106 852.382 29.3452C989.764 -18.3615 1100.63 91.5021 1112.7 174.266C1112.86 175.355 1112.03 176.319 1110.94 176.319C1105.91 176.444 1092.42 176.779 1082.29 176.779C1093.85 212.465 1110.6 304.444 1047.19 382.852C1046.48 383.732 1045.14 383.774 1044.38 382.936L1029.93 366.559C1029.93 366.559 976.529 436.339 867.67 429.972C867.67 429.972 880.99 509.093 845.597 544.066C842.079 538.286 838.309 531.627 835.545 527.103C731.922 644.757 630.77 659.417 559.692 654.056H552.99V654.098Z"
          fill="#B5B4C0"
        />
        <path
          d="M780.718 256.947L685.472 261.303L640.53 281.617L634.247 289.827C637.933 321.659 747.713 356.968 827.461 317.261C819.587 300.047 810.414 281.073 800.152 261.764L780.718 256.947Z"
          fill="#9A99A7"
        />
        <path
          d="M474.122 281.575L429.18 261.261L333.934 256.905L314.207 261.764C303.987 280.989 294.856 299.921 286.981 317.094C366.772 356.968 476.719 321.617 480.405 289.785L474.122 281.575Z"
          fill="#9A99A7"
        />
        <path
          d="M460.3 561.282C387.923 541.973 339.714 487.858 339.714 487.858C332.761 506.622 332.761 517.889 332.761 517.889C259.881 466.957 242.709 366.183 242.709 366.183C242.709 366.183 237.306 395.167 234.248 430.392C237.808 430.35 241.41 430.224 245.054 430.015C245.054 430.015 231.735 509.135 267.128 544.109C270.646 538.329 274.416 531.669 277.18 527.146C353.578 613.889 428.635 644.632 491.253 652.632L486.897 573.093L460.3 561.282Z"
          fill="#9A99A7"
        />
        <path
          d="M656.656 561.282C729.033 541.973 777.242 487.858 777.242 487.858C784.195 506.622 784.195 517.889 784.195 517.889C857.074 466.957 874.247 366.183 874.247 366.183C874.247 366.183 879.65 395.167 882.708 430.392C879.148 430.35 875.545 430.224 871.901 430.015C871.901 430.015 885.221 509.135 849.828 544.109C846.31 538.329 842.54 531.669 839.776 527.146C763.378 613.889 688.321 644.632 625.703 652.632L630.059 573.093L656.656 561.282Z"
          fill="#9A99A7"
        />
        <path
          d="M127.526 337.659C71.0655 277.596 75.1702 99.4606 206.06 88.1517C230.144 86.7695 251.212 89.5758 269.557 94.7695H269.473C157.557 58.5392 66.6257 89.3245 30.94 176.822C30.94 176.822 30.563 177.324 29.9347 178.246C18.5002 214.644 3.04475 304.444 64.4059 381.596C65.6624 383.146 68.0498 383.23 69.3901 381.722L82.7095 366.643C82.7095 366.643 136.112 436.423 244.971 430.057L256.824 389.01C256.824 389.01 183.945 397.722 127.442 337.659H127.526Z"
          fill="#8F8E9C"
        />
        <path
          d="M636.467 471.899L556.886 483.627L556.677 484.004L556.467 483.627L476.886 471.899C407.776 488.988 396.803 549.219 398.855 608.779C400.907 668.339 407.734 905.113 407.734 905.113C431.693 872.275 436.467 826.412 436.467 743.605C436.467 660.799 433.745 594.412 446.059 567.732C446.059 567.732 477.598 715.333 556.216 828.129L556.383 829.176C556.383 829.176 556.551 828.883 556.677 828.757C556.76 828.883 556.844 829.05 556.97 829.176L557.137 828.129C635.755 715.333 667.294 567.732 667.294 567.732C679.608 594.412 676.886 660.799 676.886 743.605C676.886 826.412 681.661 872.275 705.619 905.113C705.619 905.113 712.446 668.339 714.498 608.779C716.55 549.219 705.619 489.03 636.467 471.899Z"
          fill="#EEEFF3"
        />
        <path
          d="M658.582 540.926C658.582 540.926 633.828 620.507 612.718 657.072C612.718 657.072 605.639 647.606 595.838 638.35C584.865 698.329 559.357 737.784 557.891 740.088C556.383 737.784 530.917 698.329 519.943 638.35C510.142 647.606 503.064 657.072 503.064 657.072C481.954 620.507 457.2 540.926 457.2 540.926C457.2 540.926 450.75 552.025 446.101 567.732C446.101 567.732 477.64 715.292 556.257 828.087L556.425 829.135C556.425 829.135 556.592 828.841 556.718 828.716C556.802 828.841 556.886 829.009 557.011 829.135L557.179 828.087C635.796 715.292 667.336 567.69 667.336 567.69C668.76 570.79 669.974 574.475 671.021 578.622L671.65 575.02C667.168 555.544 658.666 540.926 658.666 540.926H658.582Z"
          fill="#D4D4DC"
        />
        <path
          d="M556.718 524.381C469.724 524.381 472.865 467.167 479.022 420.633C485.179 374.099 513.828 335.69 556.928 335.69H557.388C600.488 335.69 629.137 374.099 635.294 420.633C641.451 467.167 644.592 524.381 557.598 524.381H556.718Z"
          fill="#32313F"
        />
        <path
          d="M538.666 473.031C544.363 473.449 550.604 473.743 557.431 473.826C564.216 473.743 570.499 473.449 576.195 473.031C605.724 470.811 628.467 446.099 627.965 416.486C627.713 400.989 625.116 388.214 622.143 378.413C608.656 353.031 586.289 335.732 557.431 335.732H556.97C528.824 335.732 506.918 352.151 493.347 376.402C490.122 386.413 487.19 399.858 486.939 416.444C486.436 446.057 509.18 470.811 538.708 472.989L538.666 473.031Z"
          fill="#575664"
        />
        <path
          d="M370.332 172.843C370.332 172.843 324.133 81.4506 206.06 88.1521C75.1704 99.461 71.0238 277.597 127.526 337.659C183.987 397.722 256.908 389.01 256.908 389.01C256.908 389.01 306.207 254.518 370.374 172.885L370.332 172.843Z"
          fill="#32313F"
        />
        <path
          d="M206.06 88.1521C127.023 94.9793 94.2275 162.623 93.0547 228.34C120.447 217.534 231.358 182.1 304.74 280.068C323.086 243.628 345.578 204.298 370.331 172.843C370.331 172.843 324.133 81.4506 206.06 88.1521Z"
          fill="#252530"
        />
        <path
          d="M986.875 337.659C1043.34 277.596 1039.23 99.4606 908.341 88.1517C884.257 86.7695 863.189 89.5758 844.844 94.7695H844.928C956.843 58.5392 1047.78 89.3245 1083.46 176.822C1083.46 176.822 1083.84 177.324 1084.47 178.246C1095.9 214.644 1111.36 304.444 1050 381.596C1048.74 383.146 1046.35 383.23 1045.01 381.722L1031.69 366.643C1031.69 366.643 978.288 436.423 869.43 430.057L857.577 389.01C857.577 389.01 930.456 397.722 986.959 337.659H986.875Z"
          fill="#8F8E9C"
        />
        <path
          d="M744.069 172.843C744.069 172.843 790.268 81.4506 908.341 88.1521C1039.23 99.461 1043.38 277.597 986.875 337.659C930.414 397.722 857.493 389.01 857.493 389.01C857.493 389.01 808.195 254.518 744.027 172.885L744.069 172.843Z"
          fill="#32313F"
        />
        <path
          d="M908.508 88.1521C987.545 94.9793 1020.34 162.623 1021.51 228.34C994.121 217.534 883.21 182.1 809.828 280.068C791.482 243.628 768.99 204.298 744.236 172.843C744.236 172.843 790.435 81.4506 908.508 88.1521Z"
          fill="#252530"
        />
        <path
          d="M643.378 124.424C583.231 94.3506 562.582 49.4502 562.582 49.4502C535.65 73.2407 540.592 106.036 540.592 106.036C522.205 94.3506 501.975 77.3035 501.975 77.3035C488.069 106.497 493.011 126.225 493.011 126.225L611.084 128.026L643.419 124.424H643.378Z"
          fill="#B5B4C0"
        />
        <path
          d="M412.886 450.664C450.199 450.664 480.446 420.416 480.446 383.104C480.446 345.792 450.199 315.544 412.886 315.544C375.574 315.544 345.326 345.792 345.326 383.104C345.326 420.416 375.574 450.664 412.886 450.664Z"
          fill="#DEDFE5"
        />

        <LeftEyebrow />
        <LeftEye />

        <path
          d="M701.807 450.664C739.12 450.664 769.367 420.416 769.367 383.104C769.367 345.792 739.12 315.544 701.807 315.544C664.495 315.544 634.247 345.792 634.247 383.104C634.247 420.416 664.495 450.664 701.807 450.664Z"
          fill="#DEDFE5"
        />

        <RightEyebrow />
        <RightEye />

        <path
          d="M509.892 542.14C500.97 541.01 494.646 539.837 492.384 543.565C490.122 547.292 491.798 555.334 499.295 562.245C518.981 580.256 593.536 576.821 611.295 564.884C613.598 563.334 615.902 561.198 618.541 558.811C630.604 547.879 625.159 539.544 612.509 541.554C599.86 543.565 584.656 547.292 559.651 547.292C534.646 547.292 509.934 542.14 509.934 542.14H509.892Z"
          fill="#6E6D79"
        />
        <path
          d="M128.447 1108.8C93.4737 1175.9 146.458 1273.41 197.85 1290.96C198.562 1277.26 199.61 1264.07 199.61 1264.07L128.447 1108.76V1108.8Z"
          fill="#B5B4C0"
        />
        <path
          d="M977.493 1120.44C1003.08 1191.65 937.367 1281.07 884.048 1291.54C885.179 1277.89 885.933 1264.69 885.933 1264.69L977.493 1120.48V1120.44Z"
          fill="#B5B4C0"
        />

        <Paw />

        <path
          d="M1048.74 1098.45C1058.46 1108.17 1060.72 1127.81 1057.03 1142.1C1048.78 1174.14 1001.49 1197.18 979.67 1174.85C975.147 1170.24 972.843 1165.3 971.922 1160.48C970.791 1154.54 971.921 1148.71 974.644 1143.73"
          fill="#8F8F99"
        />
        <path
          d="M1009.16 1009.24C1009.16 1009.24 1055.4 1025.87 1054.81 1073.95C1054.35 1114.03 1009.49 1157.13 976.403 1138.87C969.534 1135.06 965.136 1130.16 962.414 1124.92C956.634 1113.78 958.644 1100.76 965.974 1091"
          fill="#9A99A7"
        />
        <path
          d="M911.441 969.03C857.033 983.103 845.798 1024.89 845.798 1024.89C845.798 1024.89 931.211 1030.39 939.588 1055.52C936.53 1076.38 941.724 1086.31 955.211 1093.85C960.656 1096.86 967.357 1098.62 975.609 1097.99C1015.27 1094.85 1031.4 1029.76 1006.77 995.459C983.022 962.37 929.117 966.14 929.117 966.14L927.818 966.265C922.289 966.852 916.802 967.647 911.399 969.071L911.441 969.03Z"
          fill="#B5B4C0"
        />
        <path
          d="M617.556 1286.27C619.818 1277.82 615.982 1269.46 608.988 1267.58C601.994 1265.71 594.49 1271.04 592.229 1279.49C589.967 1287.93 593.804 1296.3 600.798 1298.17C607.792 1300.04 615.295 1294.71 617.556 1286.27Z"
          fill="#32313F"
        />
        <path
          d="M572.992 1386.67C575.85 1378.41 572.62 1369.79 565.778 1367.43C558.935 1365.06 551.071 1369.84 548.213 1378.1C545.354 1386.37 548.584 1394.99 555.427 1397.35C562.269 1399.72 570.134 1394.94 572.992 1386.67Z"
          fill="#32313F"
        />
        <path
          d="M974.142 1090.62C970.875 1097.2 961.032 1101.34 953.702 1097.66C946.372 1093.97 944.278 1083.88 947.587 1077.3C950.896 1070.73 959.482 1068.38 966.812 1072.07C974.142 1075.75 977.451 1084.04 974.142 1090.62Z"
          fill="#32313F"
        />
        <path
          d="M979.587 1133.72C972.551 1135.81 962.791 1131.5 960.446 1123.63C958.1 1115.75 964.048 1107.33 971.085 1105.24C978.121 1103.14 985.744 1107.79 988.09 1115.67C990.435 1123.54 986.624 1131.63 979.587 1133.72Z"
          fill="#32313F"
        />
        <path
          d="M979.586 1166.85C973.01 1166.31 966.267 1159.44 966.895 1152.11C967.524 1144.78 975.272 1139.67 981.848 1140.21C988.424 1140.76 993.283 1147.21 992.654 1154.54C992.026 1161.87 986.162 1167.39 979.586 1166.85Z"
          fill="#32313F"
        />
      </g>
      <defs>
        <clipPath id="clip0_655_3321">
          <rect
            width="1112.46"
            height="1434.09"
            fill="white"
            transform="translate(0.238281 0.445312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Sensei;
