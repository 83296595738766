import React from "react";

import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import Footer from "../partials/Footer";
import Timeline from "../partials/Timeline";

function Journey() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        {/*  Page sections */}
        <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
          <div className="max-w-3xl mx-auto text-center pb-12">
            <div
              className="text-primary-1 md:text-3xl md:leading-normal h4 mb-4 font-ubuntu mt-28"
              data-aos="fade-up"
            >
              The Generos Journey
            </div>
          </div>
          <Timeline />
        </div>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default Journey;
