import React, { useState, createContext } from "react";
import { store } from "react-notifications-component";

const NotificationContext = createContext(null);

const NotificationContextProvider = ({ children }) => {
  const [currentNotificationId, setCurrentNotificationId] = useState(null);

  const storeNotificationId = (id) => {
    if (currentNotificationId) {
      store.removeNotification(currentNotificationId);
    }

    setCurrentNotificationId(id);
  };

  return (
    <NotificationContext.Provider
      value={{
        storeNotificationId,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationContextProvider, NotificationContext };
