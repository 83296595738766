export const NOTIFICATION_TYPES = {
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};

export const ORGS = [
  {
    id: "dd710c4c-8ef3-4827-abd3-cb79393e3310",
    name: "Charity Water",
    ein: "22-3936753",
    delay: "400",
    videoUrl: "https://youtube.com/embed/nXKYi_kw3NA",
    description:
      "Charity Water is a non-profit organization bringing clean and safe drinking water to people in developing countries.",
  },
  {
    id: "08ee5c62-ffff-4663-b7a0-a50ee97cce63",
    name: "One Tree Planted",
    ein: "46-4664562",
    delay: "800",
    videoUrl: "https://youtube.com/embed/8LrcMzAUOH0",
    description:
      "One Tree Planted is a non-profit 501(c)3 environmental tree planting charity that plant trees in countries around the world. One dollar plants one tree.",
  },
  {
    id: "132333e2-5095-4599-80ee-fd6e7e1dd2b4",
    name: "Donors Choose",
    ein: "13-4129457",
    delay: "1200",
    videoUrl: "https://youtube.com/embed/RKAVIYG64Qg",
    description:
      "Donors Choose is a United States-based nonprofit organization that allows individuals to donate directly to public school classroom projects.",
  },
  {
    id: "3c6bbf28-9358-4e34-a963-b8ba64daae0f",
    name: "The Bail Project",
    ein: "81-4985512",
    delay: "1600",
    videoUrl: "https://youtube.com/embed/LrCf5CqMhCg",
    description:
      "The Bail Project National Revolving Bail Fund is a critical tool to prevent incarceration and combat racial and economic disparities in the bail system.",
  },
  {
    id: "a7461743-3b35-4982-a684-cabc664a4802",
    name: "Best Friends",
    ein: "23-7147797",
    delay: "2000",
    videoUrl: "https://youtube.com/embed/3zec1L2LW1I",
    description:
      "Best Friends Animal Society is an American nonprofit 501 animal welfare organization that does outreach nationwide with shelters, rescue groups and members to promote pet adoption, no-kill animal rescue, and spay-and-neuter practices.",
  },
  {
    id: "aa797ae7-503f-449e-89ba-5a965894a58a",
    name: "XPRIZE Foundation",
    ein: "52-1876879",
    delay: "2400",
    videoUrl: "https://youtube.com/embed/NwNaWsQnP3A",
    description:
      "XPRIZE is a non-profit organization that designs and hosts public competitions intended to encourage technological development to benefit humanity. ",
  },
];
