import React from "react";
import Button from "../components/Button";
import { useHistory } from "react-router-dom";
import { ReactComponent as Sensei } from "../assets/sensei/standing.svg";
import { ReactComponent as Shadow } from "../assets/sensei/levitating-shadow.svg";
import LevitatingSensei from "../components/LevitatingSensei";
import { joinWaitList } from "../utils/helpers";
const CTA = () => {
  const history = useHistory();

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* CTA box */}
        <div
          className="relative bg-purple-600 py-10 px-8 md:py-16 md:px-12 rounded-lg"
          data-aos="fade-up"
        >
          {/* Background illustration */}

          {/* CTA content */}
          <div className="flex lg:flex-row flex-col mb-6 lg:mr-16 lg:mb-0 text-center lg:text-left md:items-center">
            {/* <Sensei className="md:w-96 w-80 md:px-0 px-14 md:mb-0 mb-4" /> */}
            {/* Desktop and Tablet */}
            <div className="hidden md:grid grid-cols-5 items-center">
              <div className="col-span-2 flex flex-col mt-6 mr-6 items-center justify-center">
                <LevitatingSensei className="w-1/2 mb-4 animate-levitate" />
                <Shadow className="w-1/2 px-6 animate-stretchShadow" />
              </div>
              <div className="col-span-3 flex flex-col">
                <div
                  className="mt-10 font-extrabold rounded-lg bg-primary-5 text-2xl p-6 text-topaz-500 text-center"
                  onClick={() => joinWaitList(history)}
                >
                  <span className="animate-gen-pulse-zero">
                    Request An Invite... 🍃
                  </span>
                </div>
              </div>
            </div>
            {/* Mobile */}
            <div className="md:hidden">
              <div className="flex flex-col justify-center w-full mt-6 mr-6">
                <LevitatingSensei className="md:px-0 px-14 md:mb-0 mb-4 animate-levitate" />
                <Shadow className="px-6 animate-stretchShadow" />
              </div>
              <div className="flex flex-col">
                <div
                  className="mt-10 font-extrabold rounded-lg bg-primary-5 text-xl p-6 text-topaz-500"
                  onClick={() => joinWaitList(history)}
                >
                  <span className="animate-gen-pulse-zero">
                    Request An Invite... 🍃
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
