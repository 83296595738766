import React from "react";

const CardActions = ({children}) => {
  return (
    <div className="flex w-full px-4 py-2 bg-primary-5 rounded-b-lg justify-center">
      {children}
    </div>
  )
}

export default CardActions