import React, { useState } from "react";

import { ReactComponent as Hero } from "../assets/heroes/what-if.svg";
import { ReactComponent as Zen } from "../assets/heroes/zen.svg";
import { useHistory } from "react-router-dom";
import Modal from "../utils/Modal";
import GamifiedApp from "../components/animated/GamifiedApp";
import { requestInvite } from "../utils/helpers";

function WhatIf() {
  const [deckModalOpen, setDeckModalOpen] = useState(false);
  const history = useHistory();
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div
          className="md:py-20 border-t border-gray-800"
          data-aos="fade-right"
          data-aos-anchor="[data-aos-id-gems-anchor]"
        >
          {/* Items */}
          <div className="grid gap-20" data-aos-id-features-home>
            {/* Item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div
                className="mt-6 max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-5 mb-8 md:mb-0 md:order-0 rounded-lg overflow-hidden"
                data-aos="fade-left"
                data-aos-anchor="[data-aos-id-gems-anchor]"
                data-aos-id-gamified-anchor
              >
                <Hero />
                {/* <GamifiedApp className="" /> */}
              </div>
              {/* Content */}
              <div
                className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-7"
                data-aos="fade-right"
                data-aos-anchor="[data-aos-id-gems-anchor]"
              >
                <div
                  className="md:text-4xl text-3xl h4 font-bold md:leading-normal  mb-8 text-white md:text-left text-center"
                  data-aos="fade-up"
                  data-aos-id-what-if-anchor
                >
                  The first
                  <span className=" italic text-primary-1">
                    {" generative-generosity "}
                  </span>
                  platform radically implementing a "virtual" gift economy.
                </div>
                <div
                  className="md:text-2xl text-xl text-white font-thin md:text-left mb-12 text-center"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  Join a community of radical givers creating social good and
                  economic opportunity at the same time.
                </div>

                <div data-aos="fade-up" data-aos-delay="400">
                  <a
                    className="btn text-lg rounded-lg font-bold text-white bg-purple-600 hover:bg-purple-700 w-full mb-4 sm:w-auto sm:mb-0"
                    onClick={() => history.push("/manifesto")}
                  >
                    Read The Generos Manifesto
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="max-w-lg md:max-w-none md:w-48 w-60 mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 rounded-lg overflow-hidden"
        data-aos="fade-up"
      >
        <Zen />
      </div> */}
      <Modal
        id="modal"
        ariaLabel="modal-headline"
        show={deckModalOpen}
        handleClose={() => setDeckModalOpen(false)}
      >
        <div className="relative pb-9/16">
          <iframe
            src="https://slides.com/patricklewis/generos-io/embed?token=FCRoUuML&style=hidden"
            className="absolute w-full h-full"
            title="Generos (IO)"
            scrolling="no"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          ></iframe>
        </div>
      </Modal>
    </section>
  );
}

export default WhatIf;
