import React from "react";
import classnames from "classnames";
import { motion } from "framer-motion";

const Button = ({
  text,
  role,
  onClick,
  className,
  variant = "primary-w",
  textSize = "text-lg",
  disabled,
  widthClass = "w-full",
  children = null,
}) => {
  const btnBaseClasses =
    "font-extrabold rounded-lg p-2 focus:outline-none text-center";
  const btnPrimaryClasses = `${btnBaseClasses} bg-purple-600 hover:bg-purple-700 text-white`;
  const btnPrimaryWidthClasses = `${btnPrimaryClasses} ${widthClass}`;
  const btnSecondaryClasses = `${btnBaseClasses} bg-primary-4 hover:bg-primary-3 text-primary-0`;
  const btnSecondaryWidthClasses = `${btnSecondaryClasses} ${widthClass}`;
  const btnDisabledClasses = `${btnBaseClasses} bg-primary-3 text-primary-2 pointer-events-none`;
  const btnDisabledWidthClasses = `${btnDisabledClasses} ${widthClass}`;

  const buttonClasses = classnames(
    {
      [btnPrimaryClasses]: !disabled && variant === "primary",
      [btnPrimaryWidthClasses]: !disabled && variant === "primary-w",
      [btnSecondaryClasses]: !disabled && variant === "secondary",
      [btnSecondaryWidthClasses]: !disabled && variant === "secondary-w",
      [btnBaseClasses]: !disabled && variant === "custom",
      [btnDisabledWidthClasses]: !!disabled && variant !== "disabled",
      [btnDisabledClasses]: !!disabled && variant === "disabled",
    },
    `${textSize}`,
    className
  );

  return (
    <motion.button
      role={role}
      whileTap={{ scale: 0.9 }}
      className={buttonClasses}
      onClick={onClick}
    >
      {children ? children : text}
    </motion.button>
  );
};

export default Button;
