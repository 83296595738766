import { useEffect } from "react";
import { useParams } from "react-router-dom";

function MembersRedirect() {
  const { username } = useParams();

  const redirectToMembersPage = () => {
    window.location.href = `${process.env.REACT_APP_GENEROS_APP_URI}/members/${username}`;
  };

  useEffect(() => {
    redirectToMembersPage();
  });

  return <></>;
}

export default MembersRedirect;
