import React from "react";

const Input = ({ type = "text", ...rest }) => {
  return (
    <input
      type={type}
      className="w-full font-bold p-3 mb-7 rounded text-primary-1 bg-primary-6 outline-none ring-2 focus:ring-2 ring-primary-6 border-primary-6 focus:outline-none focus:ring-primary-1 focus:border-primary-1 text-xl mobile-iphone-6-plus:text-lg placeholder-primary-2"
      {...rest}
    />
  );
};

export default Input;
