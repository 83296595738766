import { useState, useEffect } from "react";

const useLoading = ({ loading }) => {
  const [isActionDisabled, setIsActionDisabled] = useState(false);

  useEffect(() => {
    setIsActionDisabled(loading);
  }, [loading]);

  return { isActionDisabled };
};

export default useLoading;
