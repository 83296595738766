import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import "./css/style.scss";

import AOS from "aos";

import Home from "./pages/Home";
import Home2 from "./pages/Home2";
import TheGenerosManifesto from "./pages/TheGenerosManifesto";
import CommunityValues from "./pages/CommunityValues";
import Journey from "./pages/Journey";
import JoinWaitlist from "./pages/JoinWaitlist";
import JoinWaitlistConfirmation from "./pages/JoinWaitlistConfirmation";
import PageNotFound from "./pages/PageNotFound";
import Terms from "./pages/Terms";
import MembersRedirect from "./pages/MembersRedirect";

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: false,
      duration: 600,
      easing: "ease-out-sine",
    });
  });

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

        <Route exact path="/home-2">
          <Home2 />
        </Route>

        <Route exact path="/manifesto">
          <TheGenerosManifesto />
        </Route>

        <Route exact path="/community-values">
          <CommunityValues />
        </Route>

        <Route exact path="/members/:username">
          <MembersRedirect />
        </Route>

        <Route path="/journey">
          <Journey />
        </Route>

        <Route path="/join-waitlist">
          <JoinWaitlist />
        </Route>

        <Route path="/join-waitlist-confirmation">
          <JoinWaitlistConfirmation />
        </Route>

        <Route path="/terms">
          <Terms />
        </Route>

        <Route path="*">
          <PageNotFound />
        </Route>
      </Switch>

      <div
        id="breakpoint-sm"
        className="hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden w-0 h-0"
      ></div>
      <div
        id="breakpoint-md"
        className="hidden sm:hidden md:block lg:hidden xl:hidden 2xl:hidden w-0 h-0"
      ></div>
      <div
        id="breakpoint-lg"
        className="hidden sm:hidden md:hidden lg:block xl:hidden 2xl:hidden w-0 h-0"
      ></div>
      <div
        id="breakpoint-xl"
        className="hidden sm:hidden md:hidden lg:hidden xl:block 2xl:hidden w-0 h-0"
      ></div>
      <div
        id="breakpoint-2xl"
        className="hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:block w-0 h-0"
      ></div>
    </>
  );
}

export default App;
